/* .heading{
    background: linear-gradient(330deg,#FF9000 0,red 50%,#0A00B2 100%);
    background: -moz-linear-gradient(330deg,#FF9000 0,red 50%,#0A00B2 100%);
    background: -webkit-linear-gradient(330deg,#FF9000 0,red 50%,#0A00B2 100%);
    background-image: -ms-linear-gradient(330deg,#FF9000 0,red 50%,#0A00B2 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin-bottom: 10px;
    line-height: normal;
} */

.buy-head {
  border: 1px solid #11497e;
  border-radius: 4px;
  margin-right: 5px;
  font-weight: bold;
  color: #e15550;
}
