.slick-slide { height: 400px !important; }
.test-center { text-align: -webkit-center }

.carousel-container {
  width: 500px; /* Adjust as needed */
  height: 500px; /* Adjust as needed */
}

/* Ensure images fill the slides without zooming */
.slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Or adjust as needed */
}

body {
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Nunito', sans-serif;
}

.change-city{
  border: 1px solid #DDD;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  color:#999;
}

.person-small{
  background: #DDD;
    border-radius: 50%;
    height: 36px;
    text-align: center;
    width: 36px;
    cursor: pointer;
    padding-right: 70%;
}

.subcategory-mobile{
    background: #f5f4f4;
    border-radius: 6px;
    font-weight: bold;
}

.subcategory-mobile-selected{
  border : 1px solid #e85750;
  color:white !important;
  background-color: #e85750;
}
/* li {
  padding: 0px 10px;
} */
.color-voorent-red{
  color : #e8554e;
}

.color-voorent-blue{
  color : #01497f;
}

a.disabled{
  pointer-events: none;
  cursor: 'not-allowed';
}

.image-upload > input {
  display: none;
}
.entry-content > ul > li {
  margin-top:10px;
}
.cursor-pointer{
  cursor: pointer !important;
}

hr{
  border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: #DDD;
    height: 1px;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.element-border{
  border: 1px solid rgba(72, 94, 144, 0.16);
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border-radius: 0.25rem;
}

.bcrumb:before{
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  color: #6c757d;
  content: "/";
}
.person{
  background: #DDD;
    border-radius: 50%;
    height: 42px;
    text-align: center;
    width: 42px;
    cursor: pointer;
    font-size: 26px;
    color:white;
    font-weight: bold;
}
.no-white-space {
  white-space: nowrap !important;
}

.position-relative{
  position: relative !important;
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .sidebar {
      overflow-y: none;
  }
}


@media (min-width: 768px) {
  .sidebar {
      overflow-y: scroll;
      max-height: 80vh;
  }
}

.slider-info {
  background: white;
  border-radius: 2px;
  position: relative;
  padding-bottom: 25px;

}

.slider-text {
  padding: 30px;
  line-height: 30px;
}

.slider-why {
  background: #e8554d;
  padding: 2px;
  position: absolute;
  left: 0px;
  border-bottom-right-radius: 8px;
  padding: 5px 20px;
}

.slider-content {
  margin-top: 50px;
  padding: 10px;
  font-weight: bold;
}

.text-underline-border{
    border-bottom-style: solid;
    padding: 5px;
    border-bottom-width:1px;
    border-bottom-color: #01497f;
}

ion-icon {
  font-size: 42px !important;
  color: #e8554d !important;
}

.voorent-info {
  background: #f8f9fa;
}

.package-contents-icon {
  font-size: 22px !important;
  color: #DDD !important;
}

.carousel-item{
  max-height: 450px;
} 

.carousel-item img{
  max-height: 450px;
}

.nav li {
  display: inline-block;
}  

.voo-underline {
  font-weight: 300;
  display: inline-block;
  padding-bottom: 8px;
  position: relative;
  font-size: 20px;
}
.voo-underline:before{
  content: "";
  position: absolute;
  width: 80%;
  height: 1px;
  bottom: 0;
  left: 0%;
  border-bottom: 3px solid #01497f;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.testImage {
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
}



