@media only screen and (min-device-width: 320px) and (max-device-width: 420px) {
    .body {
        margin: auto;
        margin-top: 30px;
        font-family: 'Quicksand', sans-serif;
        min-width: 720px;
        overflow: auto !important;
        position: absolute;
    }
}

@media screen and (min-device-width: 421px) {
    .body {
        width: 60%;
        margin: auto;
        margin-top: 30px;
        font-family: 'Quicksand', sans-serif;
    }
}

.image-header {
    text-align: center;
    border-bottom: 2px solid #cccccc;
    padding-bottom: 20px;
}

.display-block {
    display: block !important;
}

.mt-s {
    margin-top: 5px !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 15px !important;
}

.mt-4 {
    margin-top: 25px !important;
}

td, th {
    padding: 5px;
    text-transform: capitalize;
}

.text-black-50 {
    color: rgba(0, 0, 0, .5)!important
}

.font-weight-bold {
    font-weight: bold;
}

.your-order{
    clear: both;font-weight: bold;background: #004980;padding: 10px;color: white;text-align: center;
}

.table-main{
    width: 100%;margin-top: 20px;text-align: left;
}

.bg-ddd{
    background: #ddd !important;
}

.invoice-info{
    margin-top: 20px;padding: 20px;background: #f7f7f7;
}

.voo-info{
    text-align: center;color:#707070;padding-top: 25px; font-size: 12px;
}